import React from 'react';
import Footer from "../../components/Question/Footer";
import { Routes, useParams, Route } from "react-router-dom";
import './Question.css';
import IndexComponent from "../../components/Question/Index/Index";
import QuestionComponent from "../../components/Question/Question/Question";
import ResultsComponent from "../../components/Question/Results/Results";
import { QuestionContext } from "../../contexts/QuestionContext";

const Question = () => {
    const { questionNumber } = useParams();

    return (
        <QuestionContext.Provider value={questionNumber}>
            <div className='first-background-question main-container'>
                <Routes>
                    <Route path="" element={<IndexComponent />} />
                    <Route path="question" element={<QuestionComponent />} />
                    <Route path="results" element={<ResultsComponent />} />
                </Routes>
                <Footer />
            </div>
        </QuestionContext.Provider>
    );
};

export default Question;
