import React, { useState, useEffect, useContext } from 'react';
import { QuestionContext } from "../../../contexts/QuestionContext";
import './Question.css';
import BodyComponent from "../Body/Body";
import { Button } from '@mui/material';
import { UserContext } from "../../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

const QuestionComponent = ({ onBackClick, onHomeClick }) => {
    const navigate = useNavigate();
    const questionNumber = useContext(QuestionContext);
    const { user } = useContext(UserContext);
    const [question, setQuestion] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [timeTaken, setTimeTaken] = useState(0);
    const apiUrl = process.env.REACT_APP_API_URL || 'https://apidev-laquetediana.natasquad.com';

    useEffect(() => {
        if (questionNumber === undefined) {
            console.error('Question number is undefined');
            return;
        }
        if (!user.email) {
            console.error('User email is undefined');
            return;
        }

        const fetchQuestion = async () => {
            try {
                const response = await fetch(`${apiUrl}/questions/getById/${questionNumber}`);
                const data = await response.json();
                if (response.ok) {
                    setQuestion(data.question);
                    setAnswers(data.question.Answers || []);
                } else {
                    console.error('Failed to fetch question:', data.message);
                }
            } catch (error) {
                console.error('Error fetching question:', error);
            }
        };

        fetchQuestion();

        const startTime = Date.now();

        const timerInterval = setInterval(() => {
            setTimeTaken(Math.floor((Date.now() - startTime) / 1000));
        }, 1000);

        return () => {
            clearInterval(timerInterval);
        };
    }, [questionNumber, apiUrl]);

    const handleAnswerSelection = (answerId) => {
        setSelectedAnswer(answerId);
    };

    const handleAnswer = async () => {
        if (!selectedAnswer) {
            console.error('No answer selected');
            return;
        }
        if (!user.email) {
            console.error('User email is undefined');
            return;
        }
        try {
            const response = await fetch(`${apiUrl}/questions/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.email,
                    questionId: question.id,
                    answerId: selectedAnswer,
                    timeTaken: timeTaken,
                }),
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Answer submitted successfully:', data);
                navigate(`/question/${questionNumber}/results`);
            } else {
                console.error('Failed to submit answer:', data.message);
            }
        } catch (error) {
            console.error('Error submitting answer:', error);
        }
    };

    return (
        <>
            <BodyComponent />

            <div className='question-block'>
                {question && (
                    <div className='question-content'>
                        <h4 className='question-title mt-0 mb-10 text-center'>Question N°{question.questionNumber}</h4>
                        <h3 className='question-content-text'>{question.content}</h3>
                        <form>
                            {answers.map(answer => (
                                <div key={answer.id} className='form-check'>
                                    <div className="check-container">
                                        <input
                                            type='checkbox'
                                            id={`answer-${answer.id}`}
                                            name='answers'
                                            value={answer.id}
                                            className='form-check-input'
                                            onChange={() => handleAnswerSelection(answer.id)}
                                            checked={selectedAnswer === answer.id}
                                        />
                                        <label htmlFor={`answer-${answer.id}`} className='answer-text'>
                                            {answer.content}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </form>
                    </div>
                )}
            </div>

            <div className='valider-button'>
                <Button
                    className='wizard-button'
                    onClick={handleAnswer}
                    disabled={!selectedAnswer}
                    style={
                        selectedAnswer
                            ? { backgroundColor: '#5BAC95', color: '#fff' }
                            : {}
                    }
                >
                    <h4 className='button-title commencer-button-text' style={
                        selectedAnswer
                            ? { color: '#fff' }
                            : {}
                    }>
                        VALIDER
                    </h4>
                </Button>
            </div>
        </>
    );
};

export default QuestionComponent;
