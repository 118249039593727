import React from 'react';
import logoExpanscience from '../assets/img/logo-expanscience.png';

const FooterLogo = () => {
    return (
        <div className='footer-logo-screen'>
            <img className="screen-img-logo-footer" src={logoExpanscience} />
        </div>
    );
};

export default FooterLogo;