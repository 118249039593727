import React, { useContext, useState, useEffect } from 'react';
import { QuestionContext } from "../../../contexts/QuestionContext";
import './Results.css';
import BodyComponent from "../Body/Body";
import { Button } from '@mui/material';
import { UserContext } from "../../../contexts/UserContext";

const ResultsComponent = ({ onBackClick, onHomeClick }) => {
    const questionNumber = useContext(QuestionContext);
    const { user } = useContext(UserContext);
    const [isCorrect, setIsCorrect] = useState(null);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [answerDescription, setAnswerDescription] = useState('');
    const [question, setQuestion] = useState({});
    const [answers, setAnswers] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL || 'https://apidev-laquetediana.natasquad.com';

    useEffect(() => {
        const fetchAnsweredQuestions = async () => {
            try {
                const response = await fetch(`${apiUrl}/questions/user/${user.email}`);
                const data = await response.json();

                if (response.ok) {
                    const answeredQuestion = data.answeredQuestions.find(
                        q => q.Question.questionNumber.toString() === questionNumber.toString()
                    );

                    if (answeredQuestion) {
                        setIsCorrect(answeredQuestion.isCorrect);
                    } else {
                        console.error('Question not found in answered questions');
                    }
                } else {
                    console.error('Failed to fetch answered questions:', data.message);
                }
            } catch (error) {
                console.error('Error fetching answered questions:', error);
            }
        };

        const fetchQuestion = async () => {
            try {
                const response = await fetch(`${apiUrl}/questions/getById/${questionNumber}`);
                const data = await response.json();
                if (response.ok) {
                    setQuestion(data.question);
                    setAnswers(data.question.Answers || []);
                    setAnswerDescription(data.question.answerDescription || '');

                    // Extract the first correct answer
                    const correctAnswers = data.question.Answers.filter(answer => answer.isCorrect);
                    if (correctAnswers.length > 0) {
                        setCorrectAnswer(correctAnswers[0].content);
                    } else {
                        console.error('No correct answers found for this question');
                    }
                } else {
                    console.error('Failed to fetch question:', data.message);
                }
            } catch (error) {
                console.error('Error fetching question:', error);
            }
        };

        fetchAnsweredQuestions();
        fetchQuestion();
    }, [questionNumber, apiUrl, user]);

    const handleFinish = async () => {
        window.location.href = "https://ardemo.natasquad.com/scanner";
    };

    return (
        <>
            <BodyComponent />

            <div className='question-block'>
                <h4 className='question-title mt-0 mb-10 text-center'>{isCorrect ? "BRAVO" : "DOMMAGE !"}</h4>
                <h3 className='question-content-text'>{isCorrect ? "BONNE RÉPONSE !" : "LA BONNE RÉPONSE ÉTAIT :"}</h3>
            </div>

            <div className='form-check text-block'>
                <input
                    type='checkbox'
                    name='answers'
                    className='results-form-check-input'
                    checked={true}
                    disabled={true}
                />
                <label className='results-answer-text'>
                    {correctAnswer || 'Loading...'}
                </label>
            </div>

            <div className='answer-description'>
                <p className='answer-text text-description'>
                    {answerDescription || 'Loading answer description...'}
                </p>
            </div>

            <div className='suivant-button'>
                <Button
                    className='wizard-button'
                    onClick={handleFinish}
                    style={{ backgroundColor: '#5BAC95', color: '#fff' }}
                >
                    <h4 className='button-title commencer-button-text' style={{ color: '#fff' }}>
                        SUIVANT
                    </h4>
                </Button>
            </div>
        </>
    );
};

export default ResultsComponent;
