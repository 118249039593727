import React, {useContext, useEffect, useState} from 'react';
import laquetedianaName from "../../../assets/img/laquetediana-name.png";
import './Index.css';
import { QuestionContext } from "../../../contexts/QuestionContext";
import product1 from '../../../assets/img/iana-indice-1.png';
import product2 from '../../../assets/img/iana-indice-2.png';
import product3 from '../../../assets/img/iana-indice-3.png';
import product4 from '../../../assets/img/iana-indice-4.png';
import product5 from '../../../assets/img/iana-indice-5.png';
import product6 from '../../../assets/img/iana-indice-6.png';
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {UserContext} from "../../../contexts/UserContext";

const productImages = {
    1: product1,
    2: product2,
    3: product3,
    4: product4,
    5: product5,
    6: product6
};

const IndexComponent = ({ onBackClick, onHomeClick }) => {
    const questionNumber = useContext(QuestionContext);
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const apiUrl = process.env.REACT_APP_API_URL || 'https://apidev-laquetediana.natasquad.com';

    useEffect(() => {
        const fetchAnsweredQuestions = async () => {
            try {
                const response = await fetch(`${apiUrl}/questions/user/${user.email}`);
                const data = await response.json();

                if (response.ok) {
                    console.log("Response:", data.answeredQuestions.find(
                        q => q.Question.questionNumber === 1
                    ));
                    const answeredQuestion = data.answeredQuestions.find(
                        q => q.Question.questionNumber.toString() === questionNumber.toString()
                    );

                    if (answeredQuestion) {
                        navigate(`/question/${questionNumber}/results`);
                    } else {
                        console.log('Question not found in answered questions');
                    }
                } else {
                    console.error('Failed to fetch answered questions:', data.message);
                }
            } catch (error) {
                console.error('Error fetching answered questions:', error);
            }
        };

        fetchAnsweredQuestions();
    }, [questionNumber, apiUrl, user]);

    const handleStart = async () => {
        navigate(`/question/${questionNumber}/question`);
    };

    const productImage = productImages[questionNumber] || product1;

    return (
        <>
            <img className="img-laquetediana-name" src={laquetedianaName} alt="La Quetediana Name" />
            <img className={`img-laquetediana-product img-laquetediana-product-${questionNumber}`} src={productImage} alt={`Product ${questionNumber}`} />
            <h4 className='question-text title text-center'>CLÉ N°{questionNumber}</h4>
            <div className='commencer-button'>
                <Button
                    className='wizard-button'
                    onClick={handleStart}
                    style={{ backgroundColor: '#5BAC95', color: '#fff' }}
                >
                    <h4 className='button-title commencer-button-text' style={{ color: '#fff' }}>
                        COMMENCER
                    </h4>
                </Button>
            </div>
        </>
    );
};

export default IndexComponent;
