import React from 'react';
import EmptyIcon from '../../assets/img/question_icon_empty.png';
import UpIcon from '../../assets/img/question_icon_up.png';
import FullIcon from '../../assets/img/question_icon_full.png';
import './FooterIcons.css'; // Assuming you have a separate CSS file

const FooterIcons = ({ questionNumber }) => {
    return (
        <div className="footer-icons">
            <img src={
                questionNumber >= 6 ? FullIcon
                    :
                questionNumber >= 3 ? UpIcon
                    :
                    EmptyIcon
            } alt="Icon1" className="footer-icon" />

            <img src={
                questionNumber >= 5 ? FullIcon
                    :
                questionNumber >= 2 ? UpIcon
                    :
                    EmptyIcon
            } alt="Icon2" className="footer-icon" />

            <img src={
                questionNumber >= 4 ? FullIcon
                    :
                    UpIcon
            } alt="Icon3" className="footer-icon" />
        </div>
    );
};

export default FooterIcons;
