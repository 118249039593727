import React, {useContext, useState} from 'react';
import axios from 'axios';
import bienvenueScreenLeftTop from '../../assets/img/bienvenue_screen_left_top.png';
import bienvenueScreenLeftBottom from '../../assets/img/bienvenue_screen_left_bottom.png';
import bienvenueScreenRightTop from '../../assets/img/bienvenue_screen_right_top.png';
import bienvenueScreenRightBottom from '../../assets/img/bienvenue_screen_right_bottom.png';
import laquetedianaName from '../../assets/img/laquetediana-name.png';
import { Button } from '@mui/material';
import {UserContext} from "../../contexts/UserContext";
import './Login.css';
import FooterLogo from "../FooterLogo";

const Login = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const { setUser } = useContext(UserContext);
    const apiUrl = process.env.REACT_APP_API_URL || 'https://apidev-laquetediana.natasquad.com';

    const handleLogin = async () => {
        try {
            const response = await axios.post(`${apiUrl}/users/login`, { email });
            if (response.status === 200) {
                setUser({ email });  // Set the authenticated user
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError(true);
            } else {
                console.error('Error logging in:', error);
            }
        }
    };

    const handleInputChange = (e) => {
        setEmail(e.target.value);
        setError(false);
    };

    return (
        <>
            <div className='first-background main-container'>
                <img className="bienvenue-screen-img-left-top-login" src={bienvenueScreenLeftTop} />
                <img className="bienvenue-screen-img-left-bottom-login" src={bienvenueScreenLeftBottom} />
                <img className="bienvenue-screen-img-right-top-login" src={bienvenueScreenRightTop} />
                <img className="bienvenue-screen-img-right-bottom-login" src={bienvenueScreenRightBottom} />
                <img className="bienvenue-screen-img-laquetediana-name-login" src={laquetedianaName} />
                <div className="step-bienvenue">
                    <h4 className='title mt-0 mb-10 text-center'>BIENVENUE</h4>

                    <div className={`inputWithIcon mb-10 ${error ? 'error' : ''}`}>
                        <input
                            className='input-email-login'
                            type="text"
                            placeholder="Votre e-mail professionnel*"
                            value={email}
                            onChange={handleInputChange}
                            style={error ? { borderColor: 'red', color: 'red' } : {}}
                        />
                        <i className="fa fa-envelope fa-icon" aria-hidden="true"></i>
                        {error && (
                            <button
                                type="button"
                                onClick={() => setEmail('')}
                                style={{
                                    background: 'transparent',
                                    border: 'none',
                                    color: 'red',
                                    position: 'absolute',
                                    right: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer'
                                }}
                            >
                                &times;
                            </button>
                        )}
                    </div>

                    {error && (
                        <p style={{ color: 'red', fontSize: '12px', marginTop: '-10px', fontFamily:'FormaDJRText-Regular !important'  }}>
                            Votre e-mail n'est pas reconnu.<br />
                            Contactez votre responsable informatique.
                        </p>
                    )}

                    <div className='login-button'>
                        <Button
                            className='wizard-button'
                            onClick={handleLogin}
                            disabled={!email}
                            style={
                                email
                                    ? { backgroundColor: '#5BAC95', color: '#fff' }
                                    : {}
                            }
                        >
                            <h4 className='login-button-title' style={
                                email
                                    ? { color: '#fff' }
                                    : {}
                            }>VALIDEZ</h4>
                        </Button>
                    </div>
                </div>
            </div>
            <FooterLogo />
        </>
    );
};

export default Login;
