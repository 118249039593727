import React, {useContext} from 'react';
import laquetedianaNameSmall from "../../../assets/img/laquetediana-name-small.png";
import {QuestionContext} from "../../../contexts/QuestionContext";
import './Body.css';

import product1 from '../../../assets/img/iana-indice-1.png';
import product2 from '../../../assets/img/iana-indice-2.png';
import product3 from '../../../assets/img/iana-indice-3.png';
import product4 from '../../../assets/img/iana-indice-4.png';
import product5 from '../../../assets/img/iana-indice-5.png';
import product6 from '../../../assets/img/iana-indice-6.png';

import leftPlant1 from '../../../assets/img/left-plant-1.png';
import leftPlant2 from '../../../assets/img/left-plant-2.png';
import leftPlant3 from '../../../assets/img/left-plant-3.png';
import leftPlant4 from '../../../assets/img/left-plant-4.png';
import leftPlant5 from '../../../assets/img/left-plant-5.png';
import leftPlant6 from '../../../assets/img/left-plant-5.png';

import rightPlant1 from '../../../assets/img/right-plant-1.png';
import rightPlant2 from '../../../assets/img/right-plant-2.png';
import rightPlant3 from '../../../assets/img/right-plant-3.png';
import rightPlant4 from '../../../assets/img/right-plant-4.png';
import rightPlant5 from '../../../assets/img/right-plant-5.png';
import rightPlant6 from '../../../assets/img/right-plant-6.png';
import {Button} from "@mui/material";

const productImages = {
    1: product1,
    2: product2,
    3: product3,
    4: product4,
    5: product5,
    6: product6
};

const leftPlantImages = {
    1: leftPlant1,
    2: leftPlant2,
    3: leftPlant3,
    4: leftPlant4,
    5: leftPlant5,
    6: leftPlant6
};

const rightPlantImages = {
    1: rightPlant1,
    2: rightPlant2,
    3: rightPlant3,
    4: rightPlant4,
    5: rightPlant5,
    6: rightPlant6
}

const BodyComponent = ({ onBackClick, onHomeClick }) => {
    const questionNumber = useContext(QuestionContext);

    const productImage = productImages[questionNumber] || product1;
    const leftPlantImage = leftPlantImages[questionNumber] || leftPlant1;
    const rightPlantImage = rightPlantImages[questionNumber] || rightPlant1;

    return (
        <>
            <img className={`img-left-plant img-left-plant-${questionNumber}`} src={leftPlantImage} />
            <img className="screen-img-laquetediana-name-small" src={laquetedianaNameSmall} />
            <img className={`img-right-plant img-right-plant-${questionNumber}`} src={rightPlantImage} />
            <img className={`img-laquetediana-product-small img-laquetediana-product-small-${questionNumber}`} src={productImage} alt={`Product ${questionNumber}`} />
        </>
    );
};

export default BodyComponent;
