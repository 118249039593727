import React, { useContext } from 'react';
import './assets/css/styles.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Wizard from './components/Wizard';
import Question from "./pages/question/Question";
import Login from './components/Login/Login';
import { UserContext } from './contexts/UserContext';

function App() {
    const { user } = useContext(UserContext);

    return (
        <Router>
            <Routes>
                {!user ? (
                    <Route path="*" element={<Login handleNext={() => window.location.reload()} />} />
                ) : (
                    <>
                        <Route path="/" element={<Wizard />} />
                        <Route path="/question/:questionNumber/*" element={<Question />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </>
                )}
            </Routes>
        </Router>
    );
}

export default App;
