import React, { useState, useEffect } from 'react';
import { Button, ToggleButton, ToggleButtonGroup, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import bienvenueScreenLeftTop from '../assets/img/bienvenue_screen_left_top.png';
import bienvenueScreenLeftBottom from '../assets/img/bienvenue_screen_left_bottom.png';
import bienvenueScreenRightTop from '../assets/img/bienvenue_screen_right_top.png';
import bienvenueScreenRightBottom from '../assets/img/bienvenue_screen_right_bottom.png';
import laquetedianaName from '../assets/img/laquetediana-name.png';
import laquetedianaNameSmall from '../assets/img/laquetediana-name-small.png';
import qrIconScanner from '../assets/img/qr-icon-scanner.png';
import screen2RightBottom from '../assets/img/screen2_right_bottom.png';
import imgScanner from '../assets/img/img-scanner.png';
import imgOpacaScanner from '../assets/img/img-opaca-scanner.png';
import img6StepBlanck from '../assets/img/img-6step-blank.png';
import retournImage from '../assets/img/retourn-image.png';
import homeImage from '../assets/img/home.png';
import questionImage1 from '../assets/img/question-img-1.png';
import questionImage2 from '../assets/img/question-img-2.png';
import questionImage3 from '../assets/img/question-img-3.png';
import questionImage4 from '../assets/img/question-img-4.png';
import questionImage5 from '../assets/img/question-img-5.png';
import questionImage6 from '../assets/img/question-img-6.png';
import FooterLogo from "./FooterLogo";
import Login from "./Login/Login";
import logoExpanscience from "../assets/img/logo-expanscience.png";
// import MindAr from "./mindar-viewer";

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});

const Wizard = () => {
  const [activeStep, setActiveStep] = useState(1);
  const footerLogoStep = [1, 2, 8, 9];
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState("000");
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [divMainContainerHeight, setDivMainContainerHeight] = useState(776);
  const imageUrl = `/imageTarget/3target.mind`;
  const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStart = () => {
    // window.location.href = (mode === 'DEVELOPMENT') ? baseUrl : prodUrl;
  };

    const toggleVisibility = () => {
        const rootElement = document.getElementById('root');
        const sceneARElement = document.getElementById('scene-ar-container');

        if (rootElement && sceneARElement) {
            rootElement.style.display = rootElement.style.display === 'none' ? '' : 'none';
            sceneARElement.style.display = sceneARElement.style.display === 'none' ? '' : 'none';
        }
    };

    const imagesMap = {
        questionImage1: questionImage1,
        questionImage2: questionImage2,
        questionImage3: questionImage3,
        questionImage4: questionImage4,
        questionImage5: questionImage5,
        questionImage6: questionImage6,

    };

    const renderFooterQuestions = () => {
        if(activeStep == 3)
            return (
                <div className='footer-question-screen'>
                    <img className="screen-img-question-footer" src={img6StepBlanck} />
                </div>
            );
        else{
            let selectedImageSrc;
            switch (activeStep){
                case 6:
                    selectedImageSrc = imagesMap.questionImage2
                    break
                case 7:
                    selectedImageSrc = imagesMap.questionImage3
                    break
                case 8:
                    selectedImageSrc = imagesMap.questionImage4
                    break
                case 9:
                    selectedImageSrc = imagesMap.questionImage5
                    break
                case 10:
                    selectedImageSrc = imagesMap.questionImage6
                    break
                default:
                    selectedImageSrc = imagesMap.questionImage1
                    break;
            }

            return(
                <div className='footer-question-nav-screen'>
                    <div className="group-left">
                        <img className="screen-img1-question-footer" src={retournImage} />
                    </div>
                    <div className="group-right">
                        <img className="screen-img2-question-footer" src={selectedImageSrc} />
                        <img className="screen-img3-question-footer" src={homeImage} />
                    </div>
                </div>
            );


        }
    };

    const checkAndAdjustDivHeight = () => {
        const divMainContainer = document.querySelector('.main-container');
        if (divMainContainer) {
            let currentHeight = parseInt(window.getComputedStyle(divMainContainer).height);console.log(currentHeight)
            if (currentHeight === 776) {
                divMainContainer.style.height = '851px';
                setDivMainContainerHeight(851);
            } else {
                divMainContainer.style.height = '776px';
                setDivMainContainerHeight(776);
            }
        }
    };

    useEffect(() => {
        if(activeStep == 3)
            checkAndAdjustDivHeight();
    }, [activeStep]);


  const renderComponente = () => {
    switch (activeStep) {
        case 1:
            return (
                <>
                    <img className="screen2-img-left-top" src={bienvenueScreenLeftBottom} />
                    <img className="screen2-img-right-top" src={bienvenueScreenRightBottom} />
                    <img className="screen-img-laquetediana-name-small" src={laquetedianaNameSmall} />
                    <img className="screen2-img-right-bottom" src={screen2RightBottom} />
                    <div className="step-wizard">
                        <h4 className='title mt-0 text-center'>Félicitations</h4>
                        <p className='subtitle text-center'>Vous êtes authentifié !</p>

                        <div className="screen2-text-middle mt-70 text-center">
                            <h3 className='title-middle'>DÉMARRER LA QUÊTE</h3>
                            <p className='screen2-paragraph-middle'>Incenderat autem audaces usque ad insaniam homines ad haec, quae nefariis egere conatibus, Luscus quidam curator urbis subito visus: eosque ut heiulans baiolorum praecentor ad expediendum quod orsi sunt incitans vocibus crebris. qui haut longe postea ideo vivus.</p>
                        </div>
                        <div>
                            <Button className='wizard-button-screen2' onClick={handleNext}>
                                <img className="qr-icon-scanner-screen2" src={qrIconScanner} />
                                <h4 className='button-title'>SCANNER MON PREMIER INDICE</h4>
                            </Button>
                        </div>
                    </div>
                </>
            )
        case 2:
            return (
                <>
                    <img className="screen2-img-left-top" src={bienvenueScreenLeftBottom} />
                    <img className="screen2-img-right-top" src={bienvenueScreenRightBottom} />
                    <img className="screen-img-laquetediana-name-small" src={laquetedianaNameSmall} />
                    <img className="screen2-img-right-bottom" src={screen2RightBottom} />
                    <div className="step-wizard">
                        <h4 className='title mt-0 text-center'></h4>
                        <p className='subtitle text-center'></p>

                        <div className="screen3-text-middle mt-70 text-center">
                            <h3 className='title-middle'>Vous avez déjà trouvé les indices</h3>
                            <p className='screen3-paragraph-middle'>Encore un peu de patience, les résultats seront bientôt annoncés</p>
                        </div>
                        <div>
                            <Button size="small" className='wizard-button-screen3' onClick={handleNext}>
                                <h4 className='button-title'>FIN</h4>
                            </Button>
                        </div>
                    </div>
                </>
            )
        case 3:
            return (
                <>
                    <img className="screen2-img-left-top" src={bienvenueScreenLeftBottom} />
                    <img className="screen2-img-right-top" src={bienvenueScreenRightBottom} />
                    <img className="screen-img-laquetediana-name-small" src={laquetedianaNameSmall} />
                    <div className="step-wizard">
                        <div className="container-text-scanner">
                            <h4 className='title mt-0 text-center'>SCANNER</h4>
                            <p className='subtitle text-center mb-20'>UN INDICE</p>
                        </div>
                        <div className="container-images-scanner">
                            <img className="screen-img-opaca-scanner" src={imgOpacaScanner} />
                            <img className="screen-img-scanner" src={imgScanner} onClick={handleNext} />
                        </div>

                    </div>
                </>
            )
        case 4:
            window.location.href = "https://ardemo.natasquad.com/scanner";
            // break;
        case 5:
            return (
                <>
                    <img className="screen2-img-left-top" src={bienvenueScreenLeftBottom} />
                    <img className="screen2-img-right-top" src={bienvenueScreenRightBottom} />
                    <img className="screen-img-laquetediana-name-small" src={laquetedianaNameSmall} />
                    <div className="step-wizard">
                        <h4 className='title mt-0 text-center'>BRAVO !</h4>
                        <p className='subtitle text-center'>Vous avez trouvé tous les indices et accompli la quête d’IANA</p>

                        <div className="screen2-text-middle mt-70 text-center">
                            <p className='screen2-paragraph-middle'>Vous avez mis <strong className='screen-strong-text'>{seconds}</strong> secondes <br/> pour répondre à chaque question.</p>
                            <p className='screen2-paragraph-middle'>Vous avez <strong className='screen-strong-text'>{correctAnswers}</strong> bonnes réponses <br/> sur les 6 questions.</p>
                            <p className='screen3-paragraph-middle mt-70 mb-50'>Encore un peu de patience, les résultats seront bientôt annoncés</p>
                        </div>
                        <div className='text-center'>
                            <Button size="small" className='wizard-button-screen3' onClick={handleNext}>
                                <h4 className='button-title'>FIN</h4>
                            </Button>
                        </div>
                    </div>
                </>
            )
        case 6:
            return (
                <>
                    <img className="bienvenue-screen-last-img-left-top" src={bienvenueScreenLeftTop} />
                    <img className="bienvenue-screen-last-img-left-bottom" src={bienvenueScreenLeftBottom} />
                    <img className="bienvenue-screen-last-img-right-top" src={bienvenueScreenRightTop} />
                    <img className="bienvenue-screen-last-img-right-bottom" src={bienvenueScreenRightBottom} />
                    <img className="bienvenue-screen-last-img-laquetediana-name" src={laquetedianaName} />
                    <div className="step-wizard">

                    <div className="screen2-text-middle mt-70 text-center">
                        <p className='screen-last-paragraph-middle'>Toute l’équipe marketing vous remercie de votre participation à la quête d’IANA et vous souhaite un bon séminaire !</p>
                    </div>
                    </div>
                </>
            )
      default:

    }
  };

  return (
      <div className='main-ar'>
          <div className='first-background main-container'>
              {loading ?
              <ThemeProvider theme={theme}>
                <div className='vertical-horizontal-center h-80vh'>
                    <CircularProgress />
                </div>
            </ThemeProvider> : renderComponente()}

          </div>
          { footerLogoStep.includes(activeStep) ? <FooterLogo /> : renderFooterQuestions()}
      </div>

  );
};

export default Wizard;
