import React, {useContext} from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '../../assets/img/back_arrow_icon.png';
import HomeIcon from '../../assets/img/home_icon.png';
import './Footer.css';
import FooterIcons from "./FooterIcons";
import { QuestionContext } from "../../contexts/QuestionContext";

const Footer = ({ onBackClick, onHomeClick }) => {
    const questionNumber = useContext(QuestionContext);

    return (
        <div className="footer-container">

            <IconButton onClick={onBackClick}>
                <img src={ArrowBackIcon} alt="Back" className="footer-icon-small-retour" />
            </IconButton>

            <span className="footer-text">RETOUR</span>

            <FooterIcons questionNumber={questionNumber} />

            <IconButton onClick={onHomeClick}>
                <img src={HomeIcon} alt="Home" className="footer-icon-small" />
            </IconButton>

        </div>
    );
};

export default Footer;
